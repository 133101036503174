<template>
  <section class="common-section">
    <v-container>
      <transition>
        <router-view></router-view>
      </transition>
    </v-container>
  </section>
</template>


<script>
import { mapActions } from 'vuex';
export default {
  name: "Admins",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods:{
    ...mapActions([
      'getAllAdminUsers'
    ]),
  },
  created(){
    const self = this;
    self.getAllAdminUsers();
  }
};
</script>

<style>
</style>